import type { SVGProps } from 'react';

export const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 948 336" fill="none" {...props}>
    <path
      fill="#000"
      d="M247.735 335.333V286.25h75.417V143.125H190.834v192.208H95.417V143.125H0V95.417h95.417V0h95.417v95.417h227.735V286.25h51.471l.001-46.918h47.709v-47.708h190.833v-47.708H517.735V96.207h238.557v47.709H804v191.417zm460.848-96.001H565.458v47.709h143.125z"
    />
    <path
      fill="#000"
      d="M323.152 47.708V0h95.417v47.708zM852 144h-48V96h-48V48h48V0h48v48h48v48h-48zM900 0h48v48h-48z"
    />
  </svg>
);

export const WhiteLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 948 336" fill="none" {...props}>
    <path
      fill="#fff"
      d="M247.735 335.333V286.25h75.417V143.125H190.834v192.208H95.417V143.125H0V95.417h95.417V0h95.417v95.417h227.735V286.25h51.471l.001-46.918h47.709v-47.708h190.833v-47.708H517.735V96.207h238.557v47.709H804v191.417zm460.848-96.001H565.458v47.709h143.125z"
    />
    <path
      fill="#fff"
      d="M323.152 47.708V0h95.417v47.708zM852 144h-48V96h-48V48h48V0h48v48h48v48h-48zM900 0h48v48h-48z"
    />
  </svg>
);
